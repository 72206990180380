import { Switch, Route } from "react-router-dom";
import MainWithOutSide from "./components/layout/MainWithOutSide";
import Point from "./pages/Point";
import PointDetail from "./pages/PointDetail";
import VerifyMobile from "./pages/VerifyMobile";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

function App() {
  return (
    <div className="App">
      <Switch>
        {/* <Route path="/sign-up" exact component={SignUp} />
        <Route path="/sign-in" exact component={SignIn} /> */}
        <MainWithOutSide>
          <Route exact path="/point" component={Point} />
          <Route exact path="/point-detail" component={PointDetail} />
          <Route exact path="/verify-mobile" component={VerifyMobile} />
          {/* <Redirect from="*" to="/point" /> */}
        </MainWithOutSide>
        {/* <Main>
          <Route exact path="/dashboard" component={Home} />
          <Route exact path="/tables" component={Tables} />
          <Route exact path="/billing" component={Billing} />
          <Route exact path="/rtl" component={Rtl} />
          <Route exact path="/profile" component={Profile} />

          <Redirect from="*" to="/point" />
        </Main> */}
      </Switch>
    </div>
  );
}

export default App;
